/**
 * Default React & Misc
 */
import React, { Fragment } from "react";
import { parseWithFallback } from "../components/util/Functions";
/**
 * Material-Ui
 */
import Grid from "@material-ui/core/Grid";
import CallIcon from "@material-ui/icons/Call";
import FacebookIcon from "@material-ui/icons/Facebook";
/**
 * Gatsby & Plugins
 */
import { navigate, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Logo from "-!svg-react-loader?classIdPrefix=logog-!../../assets/logoGiano.svg";
/**
 * Theme-Ui
 */
/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";
/**
 * Custom Components
 */
import { CarouselItemContainer } from "../components/Carousel.jsx";
import Footer from "../components/Footer.jsx";
import {
  GridItemCarousel,
  CarouselButtonsStyles,
  getParagraphItem,
} from "../components/MainPageGridItem.jsx";
import ItemCard, {
  ResponsiveTitleContentCard,
  ResponsiveDateCard,
} from "../components/ResponsiveItemCard.jsx";
import NavBar from "../components/NavBar.jsx";
import TitleBox from "../components/TitleBox.jsx";

const icons = [
  {
    icon: <CallIcon />,
    navigate: "tel:3939021487",
  },
  {
    icon: <FacebookIcon />,
    navigate: "https://fb.me/PoliambulatorioGiano",
  },
];

const HomeTemplate = () => {
  const data = useStaticQuery(graphql`
    query {
      keystone {
        allHomeParagraphs(where: { draft: false }) {
          title
          content
          image {
            publicUrl
          }
          order
        }
        allDoctors(
          sortBy: homeOrder_ASC
          where: { homeShow: true, draft: false }
        ) {
          name
          specialization
          coverImage {
            publicUrl
          }
          slug
        }
        allTopics(
          sortBy: homeOrder_ASC
          where: { homeShow: true, draft: false }
        ) {
          title
          coverImage {
            publicUrl
          }
          slug
        }
        allServices(
          sortBy: homeOrder_ASC
          where: { homeShow: true, draft: false }
        ) {
          title
          coverImage {
            publicUrl
          }
          slug
        }
        allEvents(
          sortBy: homeOrder_ASC
          where: { homeShow: true, draft: false }
        ) {
          coverImage {
            publicUrl
          }
          date
          title
          slug
        }
        allArticles(
          sortBy: homeOrder_ASC
          where: { homeShow: true, draft: false }
        ) {
          coverImage {
            publicUrl
          }
          pubDate
          title
          slug
        }
      }
    }
  `).keystone;
  const doctors = data.allDoctors;
  const services = data.allServices;
  const topics = data.allTopics;
  const articles = data.allArticles;
  const events = data.allEvents;
  const topParagraphs = data.allHomeParagraphs
    .filter((e) => e.order >= 0)
    .sort((a, b) => a - b);
  const bottomParagraphs = data.allHomeParagraphs
    .filter((e) => e.order < 0)
    .sort((a, b) => b - a);

  var rowNumber = topParagraphs.length;

  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content="Il Poliambulatorio Giano offre servizi medici privati spaziando in un vasto insieme di specializzazioni"
        ></meta>
        <title>Poliambulatorio Giano</title>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Poliambulatorio Giano",
            url: "https://poliambulatoriogiano.it",
            address: "Via Ancona 290, Cesena, FC, Italia",
            sameAs: ["https://www.facebook.com/PoliambulatorioGiano"],
          })}
        </script>
      </Helmet>
      <NavBar />
      <TitleBox icons={icons}>
        <style>
          {` .logog-cls-1{fill:white !important;}.logog-cls-2{stroke:white !important;}`}
        </style>
        <Logo
          sx={{
            width: ["70vw", "20vw", "20vw", "20vw", "20vw", "16vw"],
            height: "auto",
            margin: "2vw",
          }}
        />
        <Text variant="homeTitle">Poliambulatorio</Text>
        <Text
          variant="homeTitle"
          sx={{
            display: ["none", "none", "block"],
          }}
        >
          &nbsp;
        </Text>
        <Text variant="homeTitle">Giano</Text>
      </TitleBox>
      <Grid container>
        {topParagraphs.map((p, i) =>
          getParagraphItem(p.title, parseWithFallback(p.content), i, i % 2 != 0, p.image, i)
        )}
        {topics.length > 0 && (
          <GridItemCarousel
            colorNumber={rowNumber++}
            title="I Nostri Ambiti Clinici"
            buttonStyle={CarouselButtonsStyles.bottom}
          >
            {topics.map((x, i) => (
              <CarouselItemContainer
                clickHandler={() => navigate(x.slug)}
                key={i}
              >
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                  content={x.specialization}
                />
              </CarouselItemContainer>
            ))}
          </GridItemCarousel>
        )}
        {services.length > 0 && (
          <GridItemCarousel
            colorNumber={rowNumber++}
            title="I Nostri Servizi"
            buttonStyle={CarouselButtonsStyles.bottom}
          >
            {services.map((x, i) => (
              <CarouselItemContainer
                clickHandler={() => navigate(x.slug)}
                key={i}
              >
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                  content={x.specialization}
                />
              </CarouselItemContainer>
            ))}
          </GridItemCarousel>
        )}
        {doctors.length > 0 && (
          <GridItemCarousel
            colorNumber={rowNumber++}
            title="I Nostri Medici"
            buttonStyle={CarouselButtonsStyles.bottom}
          >
            {doctors.map((x, i) => (
              <CarouselItemContainer
                clickHandler={() => navigate(x.slug)}
                key={i}
              >
                <ResponsiveTitleContentCard
                  title={x.name}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                  content={x.specialization}
                />
              </CarouselItemContainer>
            ))}
          </GridItemCarousel>
        )}
        {articles.length > 0 && (
          <GridItemCarousel
            colorNumber={rowNumber++}
            title="Notizie"
            buttonStyle={CarouselButtonsStyles.bottom}
          >
            {articles.map((x, i) => (
              <CarouselItemContainer
                clickHandler={() => navigate(x.slug)}
                key={i}
              >
                <ResponsiveDateCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                  date={new Date(x.pubDate)}
                />
              </CarouselItemContainer>
            ))}
          </GridItemCarousel>
        )}
        {events.length > 0 && (
          <GridItemCarousel
            colorNumber={rowNumber++}
            title="Eventi"
            buttonStyle={CarouselButtonsStyles.bottom}
          >
            {events.map((x, i) => (
              <CarouselItemContainer
                clickHandler={() => navigate(x.slug)}
                key={i}
              >
                <ResponsiveDateCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                  date={new Date(x.date)}
                />
              </CarouselItemContainer>
            ))}
          </GridItemCarousel>
        )}
        {bottomParagraphs.map((p, i) =>
          getParagraphItem(
            p.title,
            parseWithFallback(p.content),
            rowNumber++,
            (topParagraphs.length + i) % 2 != 0,
            p.image,
            i
          )
        )}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default HomeTemplate;
