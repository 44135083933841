import React from "react";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { FaCalendar, FaClock } from "react-icons/fa";

/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";

function calculateCardHeights(cardWidth, cardHeight, cardHeightRatio) {
  const widths = Array.isArray(cardWidth) ? cardWidth : [cardWidth];
  var heights;
  if (cardHeight) {
    heights = Array.isArray(cardHeight) ? cardHeight : [cardHeight];
  } else {
    heights = widths.map((x) => "calc(" + cardHeightRatio + "*" + x + ")");
  }
  return [widths, heights];
}

export function ResponsiveCard({
  cardWidth = ["60vw", "21vw", "21vw", "14vw", "14vw", "12vw"],
  cardHeight = null,
  cardHeightRatio = 1.25,
  photoHeightRatio = 0.7,
  image = "/cardDefaultLogo.png",
  children,
  contentPaddingRatio = 0.1,
  contentPaddingTopRatio,
  contentPaddingLateralRatio,
}) {
  const [widths, heights] = calculateCardHeights(
    cardWidth,
    cardHeight,
    cardHeightRatio
  );
  var topPadding = heights.map(
    (x) =>
      "calc(" + (1 - photoHeightRatio) * contentPaddingRatio + "*" + x + ")"
  );
  var lateralPadding = topPadding;
  if (contentPaddingTopRatio)
    topPadding = heights.map(
      (x) =>
        "calc(" +
        (1 - photoHeightRatio) * contentPaddingTopRatio +
        "*" +
        x +
        ")"
    );
  if (contentPaddingLateralRatio)
    lateralPadding = heights.map(
      (x) =>
        "calc(" +
        (1 - photoHeightRatio) * contentPaddingLateralRatio +
        "*" +
        x +
        ")"
    );
  return (
    <Box boxShadow={5}>
      <Card
        sx={{
          width: widths,
          height: heights,
        }}
      >
        <CardMedia
          image={image}
          title={image}
          sx={{
            height: image
              ? heights.map((x) => "calc(" + photoHeightRatio + "*" + x + ")")
              : "0",
          }}
        />
        <div
          sx={{
            paddingTop: topPadding,
            paddingLeft: lateralPadding,
            paddingRight: lateralPadding,
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // height: heights.map(
            //   (x) => "calc(" + (1 - photoHeightRatio) + "*" + x + ")"
            // ),
            //alignItems: "center",
          }}
        >
          {children}
        </div>
      </Card>
    </Box>
  );
}

export default function ResponsiveItemCard({
  cardWidth = ["60vw", "21vw", "21vw", "14vw", "14vw", "12vw"],
  cardHeight = null,
  cardHeightRatio = 1.25,
  photoHeightRatio = 0.7,
  image = "/cardDefaultLogo.png",
  title = "Ortopedia e Traumatologia Chirurgica testamento",
}) {
  const [widths, heights] = calculateCardHeights(
    cardWidth,
    cardHeight,
    cardHeightRatio
  );
  return (
    <ResponsiveCard
      cardWidth={widths}
      cardHeight={heights}
      photoHeightRatio={photoHeightRatio}
      image={image}
    >
      <div
        sx={{
          fontSize: heights.map(
            (x) => "calc(" + ((1 - photoHeightRatio) * 0.65) / 3 + "*" + x + ")"
          ),
          fontFamily: "body",
        }}
      >
        {title}
      </div>
    </ResponsiveCard>
  );
}

export function ResponsiveDateCard({
  cardWidth = ["60vw", "21vw", "21vw", "14vw", "14vw", "12vw"],
  cardHeight = null,
  cardHeightRatio = 1.25,
  photoHeightRatio = 0.7,
  image = "/cardDefaultLogo.png",
  date = new Date(),
  title = "Evento riguardo Ortopedia e Traumatologia Chirurgica aggiungi qualcosa",
  time = false,
}) {
  const [widths, heights] = calculateCardHeights(
    cardWidth,
    cardHeight,
    cardHeightRatio
  );
  return (
    <ResponsiveCard
      cardWidth={widths}
      cardHeight={heights}
      photoHeightRatio={photoHeightRatio}
      image={image}
      contentPaddingTopRatio={0.05}
    >
      <div
        sx={{
          fontSize: heights.map(
            (x) => "calc(" + (1 - photoHeightRatio) * 0.65 * 0.2 + "*" + x + ")"
          ),
          fontFamily: "body",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
        }}
      >
        <FaCalendar sx={{ marginRight: "5px" }} />
        {date.toLocaleDateString("it-IT", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
        {time && <FaClock sx={{ marginRight: "5px", marginLeft: "10px" }} />}
        {time &&
          date.toLocaleString("it-IT", {
            hour: "2-digit",
            minute: "2-digit",
          })}
      </div>
      <hr
        sx={{
          marginTop: "1px",
          marginBottom: "1px",
          borderColor: "rgb(238, 238, 238)",
        }}
      />
      <div
        sx={{
          fontSize: heights.map(
            (x) => "calc(" + (1 - photoHeightRatio) * 0.65 * 0.3 + "*" + x + ")"
          ),
          fontFamily: "body",
        }}
      >
        {title}
      </div>
    </ResponsiveCard>
  );
}

export function ResponsiveTitleContentCard({
  cardWidth = ["60vw", "21vw", "21vw", "14vw", "14vw", "12vw"],
  cardHeight = null,
  cardHeightRatio = 1.25,
  photoHeightRatio = 0.7,
  image = "/cardDefaultLogo.png",
  title = "Dr.ssa Koleva Dimitrova Kristina",
  content = "Ortopedia e Traumatologia Chirurgica",
}) {
  const [widths, heights] = calculateCardHeights(
    cardWidth,
    cardHeight,
    cardHeightRatio
  );
  return (
    <ResponsiveCard
      cardWidth={widths}
      cardHeight={heights}
      photoHeightRatio={photoHeightRatio}
      image={image}
      contentPaddingTopRatio={0.05}
    >
      <div
        sx={{
          fontSize: heights.map(
            (x) => "calc(" + (1 - photoHeightRatio) * 0.65 * 0.3 + "*" + x + ")"
          ),
          fontFamily: "body",
        }}
      >
        {title}
      </div>
      <hr
        sx={{
          marginTop: "1px",
          marginBottom: "1px",
          borderColor: "rgb(238, 238, 238)",
        }}
      />
      <div
        sx={{
          fontSize: heights.map(
            (x) =>
              "calc(" + (1 - photoHeightRatio) * 0.65 * 0.25 + "*" + x + ")"
          ),
          fontFamily: "body",
        }}
      >
        {content}
      </div>
    </ResponsiveCard>
  );
}
